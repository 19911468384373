<template>
   <div>
    <v-banner single-line class="banner">
       <v-img
           src="https://h5.ophyer.cn/official_website/banner/num-banner-h5-culturalTourism-01.png"
           height="100%"
           :transition="false"
           class="d-flex align-center"
       >
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular
                 indeterminate
                 size="80"
                 color="red lighten-5"
             ></v-progress-circular>
           </v-row>
         </template>
          <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent" flat >
             <div class="d-flex flex-no-wrap justify-space-between ml-4">
               <div>
                 <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                   文博数字化展示
                 </v-card-title>
               </div>
             </div>
           </v-card>
         </v-container>
       </v-img>
     </v-banner>

    <!-- 功能 -->
    <v-card flat class="py-6 position-r">
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28">功能</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" class="xs-tab" >
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <div class="font-size-16 letter-spacing">{{ v.title }}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
          <v-card  color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain></v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 ">{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-2 font-size-13 color-666" v-text="v.content" v-html="v.content"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!--  -->
    <!-- <v-card class="wap5 justify-center" :flat="true" color="#F5F8FC">
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28"></v-card-title>
      <v-container>
        <v-row justify="center"  class="solutions_row pa-2 " :class="$vuetify.breakpoint.name === 'xs' ? '':''">
          <v-col cols="12" v-for="n in advantage" :key="n.id">
            <v-card :flat="true" color="#F5F8FC">
              <div class="img_title " justify="center">
                 <v-img :src="n.src" class="align-end white&#45;&#45;text"></v-img>
                <v-card-text class="pa-0 mt-1 ml-1 text-align-center">
                   <span class="font-size-12  line-height-17">{{n.title}}</span>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
     </v-container>
    </v-card> -->

    <!-- 应用场景 -->
    <v-card class="justify-center wap5" :flat="true" style="padding: 10px 0 1px;background: #FBFBFB;">
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">应用场景</v-card-title>
      <div class="con">
        <v-row>
          <v-col
            v-for="(n,index) in advantage"
            :key="index"
            class="d-flex child-flex"
            cols="12"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div class="tit">
                <div class="center">
                  <p class="p1">{{ n.title }}</p> 
                  <!-- <p class="p2">{{ n.content }}</p>      -->
                </div>         
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 方案优势 -->
    <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center  font-size-20 pa-0 line-height-28">方案优势</v-card-title>
      <div class="mt-7">
        <v-row v-for="(row,rowIndex) in Vorteil" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card mb-3" min-height="90" width="100%" flat>
            <v-img :src="row.src" max-width="65" contain position="center"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-3 font-size-16  line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.content}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>

    <!-- 精彩案列 -->
    <v-card class="warps warp4 pt-2 pb-5" color="#F5F8FC" >
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">案例</v-card-title>
      <v-row class="pa-0 px-2" style="margin:0 auto;width:100%;">
          <v-col v-for="(info,infoIndex) in anli" :key="infoIndex" :cols='6' class="d-flex justify-center" @click="goCaseDetail(info)">
             <v-card flat class="h5-card item-card" width="100%" style="border-radius:5px;">
                <v-img max-width="100%" contain :src="info.coverImg"></v-img>
                <div class="text-box px-3">
                    <v-card-text class="sizeStyle pa-0 mt-3 mb-1 title" style="font-size: 12px !important;" v-text="info.itemTitle"></v-card-text>
                    <!-- <v-card-text class="sizeStyle pa-0 tip pb-7" v-text="info.itemTip"></v-card-text> -->
                </div>
              </v-card>
          </v-col>
      </v-row>
    </v-card>
    
   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import contentData from '@/static/contentData.js';
//import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      data: contentData.SaasCloudh5,
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      list:[
        {title:"文物3D数字化",h2:'文物3D数字化',sub:'3D建模，真实还原历史文物',img:'https://h5.ophyer.cn/official_website/other/wenbo1-1.png',content:'• 采用最先进的引擎制作高精度的3D模型<br/>• 通过云端技术渲染后推送到客户端<br/>• 搭配3D动画，让文物的展示更鲜活'},
        {title:"3D虚拟展馆",h2:'3D虚拟展馆',sub:'个性化定制 更好的展示展览主题',img:'https://h5.ophyer.cn/official_website/other/wenbo1-2.png',content:'• 采用最先进的引擎制作高精度的3D模型<br/>• 按照客户需求设计，满足个性化需求'},
        {title:"VR实景展馆复刻",h2:'完美还原线下场馆',sub:'点击即可查看商品详情',img:'https://h5.ophyer.cn/official_website/other/wenbo1-3.png',content:'• 720°可交互全景，百分百还原线下展厅<br/>• 全景编辑制作平台，简单操作即可生成场景<br/>• 热点、嵌入、虚拟人物等特色功能，让展馆更智能'},
      ],
      Vorteil:contentData.DigitalMuseum.warp1.charList,
      // [
      //   {
      //     img: "https://h5.ophyer.cn/official_website/other/saas-zb-icon1.png",
      //     title: '1:1场景还原',
      //     text: '完美还原历史场景，呈现逼真效果'
      //   },
      //   {
      //     img: "https://h5.ophyer.cn/official_website/other/saas-zb-icon2.png",
      //     title: '文物3D建模',
      //     text: '详尽的采集文物数据，精细化建模'
      //   },
      //   {
      //     img: "https://h5.ophyer.cn/official_website/other/saas-zb-icon3.png",
      //     title: '线上游览',
      //     text: '强大VR技术，线上完美复刻博物馆'
      //   },
      //   {
      //     img: "https://h5.ophyer.cn/official_website/other/saas-zb-icon4.png",
      //     title: '数字化运营',
      //     text: '通过数字管控系统 在线管理博物馆'
      //   }
      // ],
      advantage:[
        { title: '3D数字博物馆', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-1.png'},
        { title: '文物3D数字化保护', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-2.png'},
        { title: 'VR数字展馆建设', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-3.png'},
      ],
     anli:[textData.anli.hanfuwenhuazhou,textData.anli.qiaojiadayuan,textData.anli.hongcunjiyi,textData.anli.anlongyingshi],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail(item){
      //console.log(item,'item')
      this.$router.push({ 
        name: 'h5CaseDetail',
        query: {
          source: 'qita',
          anli: item.self
        }
      })
    },
    handleTabBtn: function (type){
      const maxTab = this.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>


.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .h5-banner-title{
  margin-top: 31px !important;
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  background: #FBFBFB;
  padding: 10px 0;
  margin-top: 40px;
  .public-title{
    margin: 45px auto 65px !important;
  }
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}
.warp1 {
  .con {
    width: 1080px;
    margin: 20px auto 0;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.wap3{
  background: #F5F8FC;
  padding-top: 29px;
  .img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 0px;
      border-radius: 10px;
      //width:167px ;
      //height: 260px;
      object-fit:contain;
      //margin-left: 15px;
      margin-bottom: 10px;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
}
.warp4{
  width: 100%;
  .metaverse-title{
    margin-top: 30px !important;
    margin-bottom: 0px !important;
  }
}
.wap5{
  width: 1200px;
  margin: 0 auto;
  .public-title {
    margin-top: 57px !important;
  }
.con {
    margin: 25px;
    .box-img {
      width: 300px;
      height: 210px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      .tit{
         background-color: rgba(25, 26, 27, 0.5);
         width: 100%;
         height: 100%;
         position: relative;
         color: #fff;
         font-size: 24px;
         display: flex;
         justify-content: center;
         align-items: center;
      }
      .center{
         position: absolute;
         padding: 12px;
         text-align: center;
         .p1{
           font-size: 18px;
           font-family: SourceHanSansCN-Bold, SourceHanSansCN;
           font-weight: bold;
           color: #FFFFFF;
           line-height: 27px;
         }
         .p2{
           font-size: 14px;
           font-family: SourceHanSansCN-Regular, SourceHanSansCN;
           font-weight: 400;
           color: #FFFFFF;
           line-height: 16px;
         }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
// .metaverse-title {
//     margin-top: 40px;
//     margin-bottom: 25px;
//     color: #FFFFFF;
//     text-align: center;
//     position: relative;
//     z-index: 6;
//     p {
//       font-weight: 500;
//       font-size: 20px;
//       font-family: PingFangSC-Medium, PingFang SC;
//       color: #FFFFFF;
//       line-height: 45px;
//       text-shadow: 0px 0px 6px rgba(7, 0, 67, 0.3);
//     }
// }
::v-deep .warp3{
  .max-width-1200{
    width: 1080px;
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      // width:400px ;
      // height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
.advantage_card{
  transition: 0.3s;
  width: 300px;
  margin: 0 auto;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>